import React from 'react'

import Nav from '../components/nav'
import Layout from '../components/Layout'
import Thanks from '../components/Thanks'
import Footer from '../components/Footer'
import Social from '../components/Social'

const Home = () => (
  <Layout>
    <Nav />
    <Thanks />
    <Social />
    <Footer />
  </Layout>
)

export default Home
