import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FaArrowAltCircleLeft } from 'react-icons/fa'

import Back from '../images/back-lamp.jpg'

const ThanksWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url(${Back});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    color: ghostwhite;
    font-size: 5rem;
    margin: 0;
  }
  p {
    color: ghostwhite;
    font-size: 1.5rem;
    font-weight: 300;
    margin-top: 0.3rem;
    margin-bottom: 3rem;
  }
  a {
    color: ghostwhite;
    text-decoration: none;
    font-size: 1.1rem;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }
`

const ThanksContent = styled.div`
  background: rgba(0, 0, 0, 0.8);
  padding: 2rem 3rem;
  border-radius: 5px;
  border: 5px solid rgba(255, 255, 255, 0.03);
  border-bottom: 7px solid crimson;
`

const Thanks = () => {
  return (
    <ThanksWrapper>
      <ThanksContent>
        <h2>Merci!</h2>
        <p>Nous vous contacterons dans les plus brefs délais.</p>
        <Link to="/">
          <FaArrowAltCircleLeft style={{ marginRight: '0.5rem' }} />
          Retour à la page d'accueil
        </Link>
      </ThanksContent>
    </ThanksWrapper>
  )
}

export default Thanks
